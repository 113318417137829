/************************************
          PARTIALS: LOADER
************************************/
.loader-wrapper {
    width: 100vw;
    padding-top: 49vh;
    padding-left: 40%;
    padding-right: 40%;
    height: 100vh;
    position: fixed;
    transition: 0.5s linear;
    background: $body-bg;
    z-index: 100;
    top:0px;
    left:0px;
}

.loader-wrapper.hidden {
	background: rgba(0,0,0,0);
}
.loader {
  height: 5px;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: #ddd;
}
.loader:before{
  display: block;
  position: absolute;
  content: "";
  left: -200px;
  width: 200px;
  height: 4px;
  background-color: $bold-color;
  animation: loading 2s linear infinite;
}
@keyframes loading {
    from {left: -200px; width: 30%;}
    50% {width: 30%;}
    70% {width: 70%;}
    80% { left: 50%;}
    95% {left: 120%;}
    to {left: 100%;}
}