@media only screen and (max-width: 768px) {
  .project-preview {
    width: 100%;
    // border-top: solid .5px lightgray;
    // margin-top: 30px;
	padding-top: 20px;
	p{
		// font-weight: bolder;
	}
  }
}


@media only screen and (max-width: 576px) {
  
	body{
		// font-size: 1.1rem;
		font-weight: 200;
	}
	h3{
		font-size: 7vw!important;
	}
	p{
		//font-size: 4.5vw!important;
		font-size: smaller;
		font-weight: bold;
	}

	.about{
		padding: 0 10%;
		a{
			color: $bold-color;
			font-weight: bolder;
		}

	}

	.about-press-links{
		border-left: unset;
		padding-top: 14%;
		margin-left: -4%;
		// border-top: dashed 1px black;
	}

	.profile-image{
		margin-top:20%;
		padding: 5% 30% 10% 0;
		height: unset;
		border-bottom: solid black 5px;
	}

	.loader-wrapper {
		width: 100vw;
	}

	.project-design-goals{
		img {
		width: 20%;
		margin: 0;
		}
	}
	.triad.small{
		img {
		width: 50%;
		}
	}

  nav{
  	margin: 0;
  }

	header {
		
		// nav{
		// 	margin: 1.4em 0 3.5em;
		// }
		.navbar-toggle{
			background: #231f20;			
			// width: 30px;
			height: 30px;
			margin-top: -15px;
			// margin-right: 10px;	


		}

	}
	.header-typ .navbar-toggle .icon-bar {
		background-color: white;
	}

	.header-typ{
		position: fixed;
		width: 100%;
		margin-top: -5%;
		z-index:1;
		top: 0;	
		margin-left:-20px;
		width: 110vw;;
	}

	.header-typ nav{
		margin: 0;
	}
	.header-typ .navbar{
		padding: 0 1rem 0.5rem 1.5rem;
	}

	.header-typ .navbar-toggle {
		margin-top: 10px;
		// margin-right: 10px;	
	}

	.header-landing .navbar span{
		margin-right: 5px;
	}
	    
	.navbar {
		margin: 0px;
		padding: 5%;
	}
	
	.navbar-divider, .navbar-text{
		padding-top: 0; 
		padding-bottom: 0;
	}

	.navbar-text{
	    background: orange;
	    padding: 0 15px;		
	}

	.navbar-dark .navbar-nav .nav-link{
		color: white;
	}

	.nav-inline {
		display: inline;
	}

	.carousel-item img,
	.carousel-item img {
	  min-width: 150%;
	  margin-left: -25%;
	}

	footer {
		text-align: center;
		padding-bottom: 25px;
		margin-top: 3em;
		border-top: solid 1px darkgray;
		padding-top: 12%;
		span{
			display:block;
		}
		.footer-right {
			float:none;
		}
		.footer-social{
			-webkit-filter: invert(40%);
			filter: invert(40%);
		}
	}

  	// .project-container{
	//   	margin-top:20%;
	// }
	  
	.project-header{
		text-align: center;
		width: 100%;
		padding: 10% 15%;
		// margin-top: 4.2%;

		em {
			font-size: .8em;
		}
		h3{
			// padding: 5% 10% 5% 10%;
			padding: 10% 0;
			overflow-wrap: break-word;
			hyphens: auto;
			font-size:smaller;
		}
		h6{
			padding-top: 20%;
			padding-bottom: 20%;
			a{
				padding: 4% 6%;
			}
		}
	}
	.proj-descrip {
		font-size: .9em;
		font-weight: 100;
		line-height: 1.3;
	}

	.index-page{
		padding-right:10%;
		//font-size: 1.3rem;
		font-size: 4.5vw;
		padding-bottom:10%;
	}

	.header-landing{
	    margin: 10px 0 30px 0;
	}
	
	.header-landing .navbar{
		padding-right: 0px;

	}
	.carousel-featured{
		margin-top: 10px;
	}
	
	.carousel-featured-caption{
		position: static;
		width: 100%;
	    font-size: 4.5vw!important;
	}

	.browse>.row>.header-typ>.bg-primary {
		background-color: rgba(0,0,0,0)!important;
		padding: 3%;
	}

	.btn-primary{
	    background-color: #0074d9;
	}

	#landing-logo{
		width: 30vw;
		height:auto;
		padding-top: 20px;
	}

	h1.typewriter{
		font-size: 6vw!important;
	}



	.project-generic-image img {
		min-height: 350px;
		object-fit: cover;
	}

	.project-4-col-image {
		img{
			padding: 10%;
		}
	}


	.more-work-text{
		text-align: center;
		margin-top: 0;
		margin-bottom: 0.5rem;
		// margin-top:80px;
	}
	
	.browse{
		padding-top:20%;
	}

	.projects-list-menu{
		border-right:none;
	}

	.about-page{
		margin:0 -15px;
	}

	// .about-text{
	// 	padding-left:15px;
	// }

	.about-image{
		margin-top: 4.5rem;
	}

	.about-text{
		margin-top:0;
	}


	.project-block{
		p {
			padding-top:10%;
			font-size: smaller;
		}
	}

	.project-generic-text{
		h6{
			margin: 15% 0 0 0;
		}
	} 

	.project-end{
		display: block;
		margin-bottom: 0;
	}

	.project-nav-link{
		margin: 0;
	}

	.featured-text-heading {
		padding: 0;
		h4{
			background: black;
			font-size: 1.2rem;
		}
	}

	.proj-text-heading{
		h5{
			font-size: 1.3rem;
			line-height: 1.8rem;
		}
	}



}