/************************************
CORE FILE TO IMPORT STYLES
STYLE.SCSS
author: AV
************************************/

@import "variables.scss";
@import "../../node_modules/bootstrap/scss/bootstrap.scss";



/*** FONTS ***/
@import url('https://fonts.googleapis.com/css?family=Roboto:300,500,700|Roboto+Mono|Roboto+Condensed:700');



/*** PARTIALS ***/
@import "partials/header.scss";
@import "partials/header-typ.scss";
@import "partials/loader.scss";
@import "partials/footer.scss";
@import "partials/carousel.scss";
@import "partials/carousel-featured.scss";


/*** MIXINS ***/
/// Mixin to customize scrollbars
/// Beware, this does not work in all browsers
/// @author Hugo Giraudel
/// @param {Length} $size - Horizontal scrollbar's height and vertical scrollbar's width
/// @param {Color} $foreground-color - Scrollbar's color
/// @param {Color} $background-color [mix($foreground-color, white, 50%)] - Scrollbar's color
/// @example scss - Scrollbar styling
///   @include scrollbars(.5em, slategray);
@mixin scrollbars($size, $background-color, $foreground-color: mix($highlight-color, lightgray,  10%)) {
  // For Google Chrome
  ::-webkit-scrollbar {
      width:  $size;
      height: $size;
  }

  ::-webkit-scrollbar-thumb {
      background: $foreground-color;
  }

  ::-webkit-scrollbar-track {
      background: $background-color;
  }

  // For Internet Explorer
  body {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}




/* 
	FIXME: Some of these should be moved to 
	'partials' files if this ever gets unwieldy 
*/

html {
	@include scrollbars(.5em, $body-bg);	
}

body {
	font-weight: 100;
  // background-color: #ccc;

}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	letter-spacing: 2px;
}

.body-text {
	font-weight: 100;
	letter-spacing: .5px;
}
.index-page{
  padding-right:30%;
  font-size: 1.25em;
  //color: $body-bg;
  color:black;
  margin-top:-5%;
  a {
    //color:$body-bg;
    color:$highlight-color;
  }
  //a:hover, a:link{
    //color: white;
    //text-decoration: underline!important;
  //}
  a:hover{
    background: orange;
  }
  a:link{
    //background: darken($highlight-color, 10%);
    background: #e6e6e6;
    margin: 0.1rem;
    //padding: 0.02rem 0.2rem;
    padding: 0 0.2rem;
    //text-decoration: underline!important;
  }
}

.more-work-row{
  background: darken( $body-bg, 5% );
  margin: 1rem;
  height: 20rem;

}


.more-work-text{
  background:black;
  margin-top:3rem;
  margin-bottom:-6rem;  
  padding: 1rem;

}





a:hover{
	color: $highlight-color;
}

a:hover, a:visited, a:link, a:active {
    text-decoration: none;
    cursor: pointer;
}


// .bg-primary{
//   background-color: rgba(0,0,0,.2)!important;
// }

//About page
.about-image{
  max-width: 100%;
  padding: 0 15px;
  opacity: .5;
  margin-top: -4rem;
}
.about-text{
  text-align: center;
  margin-top: -5%;
  padding: 2% 20% 10% 19%;
}

//Carousel overrides
.carousel-control.left {
  background-image: none !important;
}

.carousel-control.right {
  background-image: none !important;  
}

//Page specific
.browse {
  min-height: 480px;
}

.browse-divider {
  border-color: $link-color
}

.project-nav .category {
	padding-left: 24px;
}

.project-nav > div:hover{
  background: $body-bg;
}

.projects-list-menu{
  border-right: dashed 2px white;
  margin-right: 5%;
  padding-right: 2%;
}

.category {
	display: none;
}

.category.active{
	display: block;
}

.category-link :hover{
	color: red;
}

.category-description {
  margin-top: 30px;
  padding-top: 20px;
  border-top: 2px dashed white;

}

.masonry-metadata {
  font-size: 0.8rem;
  color: black;
  mark {
    background: yellow;
  }
}

.masonry-date {
  font-size: 0.8rem;
  margin-top: 0.5rem;
}

.project {
	//max-width: $project-width;
  //text-align: center;
}

.landing-icon {
  //background: #69f;
  padding:  5% 0%;
  
}

.landing-icon img {
	margin-bottom: 15px;
}

.landing-icon.sm img {
	width: 64px;
}

.project-preview {
  width: 50%;
  float: left;
  display: inline-block;
  // color: white!important;
  color: black!important;
  // padding: 8px;
  padding: 2%;
}

.grid-width{
  width: 33.33%; 
  padding: 1.5rem;
}

.dark-hover{
  :hover{
    background: darken( $body-bg, 5% );
  }
}

.light-hover{
  :hover{
    background: lighten( $body-bg, 5% );
    // border: solid .5px white;
    // background: #f0ad4e;
  }
}

.project-preview h5 {
  font-family: 'Roboto Mono';
  font-weight: 500;
  font-size: 1.1em;
  text-transform: uppercase;
  // padding-top: 1em;
}

.project-preview p {
  font-size: .9em;
  font-weight: 100;
  letter-spacing: 1px;
}

.project-preview img {
  width: 100%;
  filter: brightness(95%);
  padding-bottom: 0.5rem;
}

.project-preview img:hover {
  filter: brightness(100%);  
} 

.proj-thumbnail {
  padding: 5px 5px 8px 5px;
  border-bottom: 2px dashed white
}

.proj-thumbnail-dark {
  padding: 5px 5px 8px 5px;
  border-bottom: 1px dashed black;
}

.project-block {
	margin-bottom: 20px;

  p {
    letter-spacing: 1px;
    font-size: 1.1em;
    line-height: 1.6em;
    color:black;
    //padding:10%;
  }

  img {
    width: 100%;    
  }

}

.project-container{
  // padding: 1.25%;
  padding: 1.5%;
  margin-top: -6%;
  text-align:center;
  
}

.project-siderbar{
  position: fixed;
}

.project-header {
	text-align: center;
  margin-bottom: -20px;
  //background: #eee;
  background: #fff;

  // background: #686cf3;
  padding: 10% 20%;
  h1{
    font-size: 1rem;
    font-weight: 400;
    font-family: 'Roboto', monospace;
    padding-bottom: 5%;
  }
  h3 {
		//text-transform: uppercase;
    font-size: 3rem;
    font-weight: 400;
		font-family: 'Roboto', monospace;
		letter-spacing: 1rem;
    // color:white;
    color: lightgray;
    //mix-blend-mode: exclusion;	
    padding: 0 10%;	
    padding-bottom: 5%;
    text-transform: uppercase;
	}
  h4 {
    color: gray;
    font-size:1rem;
    line-height: 1.3rem;
  }
  h5 {
    font-size:1rem;
    line-height: 1.3rem;
  }
  h6{
    padding-top: 7%;
    a{
      // background: lighten(#686cf3, 5%);
      background:lightgray;
      padding: 1% 2%;
    }
    a:hover{
      background:orange;
    }
  }
  p {
    font-size: 1.2em;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
    color: black;
    padding: 5% 0;
  }

}

.project-header-text{
  background: #faefdb;
  color:#ff1a1d;
  padding: 5% 20%;
  h3 {
    line-height: 2rem;
    font-size: 1.25rem;
  }
}

.proj-descrip {
	margin-top: 15px;
	// margin-bottom: 30px;
}

.project-end {
  margin-top: 120px;
  display: inline-flex;
  // background: #ccc;
  // padding: 10%;
  // width: 100%;
  margin-bottom: -10rem;
}


.project-design-goals{
  img {
    width: 40%;
    margin: 0 20% 20% 20%;
  }
  h5{
    padding-bottom: 5%!important;
  }
  h6{
    padding: 5%;
    line-height:1.5;
  }
  p{
    padding: 0 10% 5%;
  }
  .row div {
    margin-bottom: 30px;
  }
  
}

.project-4-col-image{
  h5{
    background: black;
    color: white;
    font-weight: 400;
    font-size: 1rem;
    // padding: 3% 0;
    text-transform: uppercase;
  }
}


.proj-text-heading{
  font-weight: 500;
  padding-bottom: 3%;
}


.triad {
  p {  
    font-size: 1em;
    padding: 0 3%;
  }

}


.project-divider{
   margin-top:5%;
   margin-bottom:5%;
   height:1px;
   width:100%;
}


.project-divider-line{
   height:1px;
   width:100%;
   border-top:1px dashed gray;
}

.project-generic-2-col-image img {
  margin-bottom: 15px;
}

.project-generic-text{
  padding: 10%;
  h6{
    background: #e2e2e2;
    padding: 2%;
    margin: 5% 20% 0 20%;
  }
  h6:hover{
    background: black;
  }
}

.project-generic-video {
  position: relative;
  margin-bottom: 8%;
  padding: 1% 0;
  background:#ddd;
}

.project-generic-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.project-nav-link{
  margin: 0 20px;
  mark:hover{
    background: $highlight-color;
  }
}

.project-generic-list{
  p{
    padding-bottom: 5%;
  }
  padding: 10%;
}

.project-text-list{
  color: $bold-color;
  list-style-type: none;

  h5{
    font-size:1rem;
    padding: 1% 5%;
    font-weight: 400;
    
  }
}

.featured-text-heading{
  padding: 0 20%;
  background: black;
  h4{
    background: $bold-color;
    color: white;
    padding: 5%;
    font-size: 1.3rem;
    font-weight: 300;
    letter-spacing: 0.2rem;
    line-height: 2rem;
    font-family: 'Roboto Mono';
  }
}


/*CSS Typewriter effect -
Borrowed from Geoff Graham - https://css-tricks.com/snippets/css/typewriter-effect/
*/

.typewriter{
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: .05em solid orange; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  // margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: .15em; /* Adjust as needed */
  animation: 
    typing 3.5s steps(40, end),
    blink-caret 1.5s step-end infinite;
}

/* The typing effect */
@keyframes typing {
  from { width: 0 }
  to { width: 260px }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: orange; }
}


//Animations

.svg-draw{
    stroke: $highlight-color;
    stroke-width: 2;
    stroke-dasharray: 500px;
    stroke-dashoffset: 500px;
    animation-name: draw;
    animation-duration: 10s;
    animation-fill-mode: forwards; // Stay on the last frame
    animation-iteration-count: 1; // Run only once
    animation-timing-function: linear;
}


.svg-colour{
	fill: transparent;
    animation-name: colour;
    animation-duration: 4s;
    animation-fill-mode: forwards; // Stay on the last frame
    animation-iteration-count: 1; // Run only once
    animation-timing-function: linear;

}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes colour {
  to {
    fill:$highlight-color;
  }
}


.index-nav {
	color: $highlight-color;
	font-weight: 500;
	text-transform: uppercase;
	letter-spacing: 2px;
	background: linear-gradient(to bottom, rgba(102,153,255,.5) 50%, $body-bg 50%);
	background-size: auto 4px;
	padding: 20px;
	animation-name: projectlist;
    animation-duration: 2s;
    animation-fill-mode: forwards; // Stay on the last frame
    animation-iteration-count: 1; // Run only once
    animation-timing-function: linear;
    animation-delay: 5s;
}

@keyframes projectlist {
  to {	
	color: white;
  }
}



/*Modal-
*/

.contact {
	height: 600px;
  background: $link-color;
  text-align: center;
  margin-bottom: -100px;

  a {
    color: white;
  }

  a:hover {
    color: black !important;
  }

}

.invert {
  filter: invert(100%);
}

// .plyr__play-large svg{
//   width:60px;
//   height:60px;
// }

.plyr__progress--played, .plyr__volume--display{
  color: #fff;
}

.plyr__play-large{
  background: #ddd;
  padding: 30px;
  border: 6px;
}

.plyr__play-large:hover{
  color:$bold-color;
}

.plyr--video .plyr__controls button:hover{
  background: #ddd;

}

.profile-image{
  max-width: 100%;
  height:300px;
  margin: -2% 0;
}

.about-page{
  margin:5px 0px;
  margin-top: -15px;
  margin-bottom: -100px;
  background: $highlight-color;
}

.about-press-links{
  border-left: dashed 2px black;
  font-size: smaller;
  h6{
    font-weight: bold;
  }
  ul{
    list-style-type:none; 
    padding-left:0;
  }
  li{
    padding-bottom:3%;
  }
}


@-webkit-keyframes Gradient {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}

@-moz-keyframes Gradient {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}

@keyframes Gradient {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}



@import "partials/mediaqueries.scss";


