
/************************************
          PARTIALS: HEADER-TYP
************************************/
.header-typ{
	
	nav {
		margin:.4em 0 3.5em 0;
		// margin: .4em -.2em 3.5em -.2em;
	}


	.header-icon {
		height: 15px;
	}

	.navbar{
		font-family: 'Roboto Mono',Roboto, "Helvetica Neue", Arial, sans-serif;
		font-weight: 300;
		letter-spacing: 2px;
		text-transform: lowercase;
		// margin-bottom:2em;
		margin-top: 1em;
		padding:0rem 1rem;		
	}

	.nav-item{
		padding-left:30px;
	}

	.navbar-dark .navbar-toggler{
		border-color: none;
	}

	a {
		color: white;
		:hover{
			background-color: orange;
			padding: 2px;
			transition: color 0.9s;
		}
	}

	button:focus{
		outline: none;
	}

	.navbar-toggle {
	  border: none;

	  .icon-bar {
	    width: 28px;
	    height: 3px;
	    background-color: orange;
	    text-align: center;
	    margin: 5px 0;
	    transition: all 0.2s;
 
	  }

	}

	.top-bar {
		transform: rotate(45deg);
		transform-origin: 10% 10%;
	}
	.middle-bar {
		opacity: 0;
	}
	.bottom-bar {
		transform: rotate(-45deg);
		transform-origin: 10% 90%;
	}	

	.navbar-toggle.collapsed {
	  .top-bar {
	    transform: rotate(0);
	  }
	  .middle-bar {
	    opacity: 1;
	  }
	  .bottom-bar {
	    transform: rotate(0);
	  }
	}

}